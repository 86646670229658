import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponsePageable } from '../models/responsePageable.model';
import { environment } from 'environments/environment';
import { Supplier } from '../models/supplier.model';
import { Paises } from '../models/paises.model';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  apiUrl = environment.partialApiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  idCustomer;

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.auth.tokenPayLoadSubject
      .asObservable()
      .subscribe((x) => (this.idCustomer = x.customer));
  }

  getOneSupplierByTaxId(taxId: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl + '/suppliers?taxId=' + taxId
    );
  }

  getOneSupplierById(id: number): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl + '/supplier/' + id + '/fornecedores'
    );
  }

  getOneSupplierByCnpj(taxId: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl + '/suppliers?taxId=' + taxId + '&size=1'
    );
  }

  getOneSupplierByCNPJ(taxId: string): Observable<Supplier> {
    let supplier: BehaviorSubject<Supplier> = new BehaviorSubject<Supplier>(
      new Supplier()
    );
    this.http
      .get<ResponsePageable>(this.apiUrl + '/suppliers?taxId=' + taxId)
      .subscribe((page: ResponsePageable) => {
        supplier.next(page.content[0]);
      });
    return supplier.asObservable();
  }

  getSupplierOrdersByCnpj(idSupplier: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/suppliers/' +
        idSupplier +
        '/orders?tipoPedidoa=QUALIFICACAOINTEGRACAO&tipoPedidob=QUALIFICACAO&size=10'
    );
  }

  getSupplierOrdersByCnpjAndTemplateId(
    idSupplier: string,
    idTemplate: string
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/suppliers/' +
        idSupplier +
        '/orders?tipoPedidoa=QUALIFICACAOINTEGRACAO&tipoPedidob=QUALIFICACAO&idTemplate=' +
        idTemplate +
        '&size=10'
    );
  }

  getSupplier(
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl + '/supplier/client?page=' + pageIndex + '&size=' + pageSize
    );
  }

  getSuppliersByCodeCountry(code: string, pageIndex: number, pageSize: number) {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/supplier/client?page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&codigoPais=' +
        code
    );
  }

  getSuppliersByTaxID(taxID: string, pageIndex: number, pageSize: number) {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/supplier/client?page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&taxID=' +
        taxID
    );
  }

  discoverSuppliers(
    pageIndex: number,
    pageSize: number,
    municipio: string,
    uf: string,
    razaoSocial: string,
    cnpj: string,
    cnaes: string
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/discover-suppliers?page=' +
        pageIndex +
        '&size=' +
        pageSize +
        `&municipio=${municipio}&uf=${uf}&razaoSocial=${razaoSocial}&cnpj=${cnpj}&cnaes=${cnaes}`
    );
  }

  discoverSuppliersById(idSupplier: string): Observable<Supplier> {
    return this.http.get<Supplier>(
      this.apiUrl + '/discover-suppliers/' + idSupplier
    );
  }

  postSupplier(supplier): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + '/suppliers',
      supplier,
      this.httpOptions
    );
  }

  updateSupplier(supplier): Observable<any> {
    return this.http.put<any>(
      this.apiUrl + '/supplier/client',
      supplier,
      this.httpOptions
    );
  }

  getSupplierByCnpj(cnpj: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl + '/suppliers?taxId=' + cnpj
    );
  }

  getSupplierById(idSupplier: string): Observable<Supplier> {
    return this.http.get<Supplier>(this.apiUrl + '/suppliers/' + idSupplier);
  }
}
