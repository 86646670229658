import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'simple-warning',
  templateUrl: './simple-warning.component.html',
  styleUrls: ['./simple-warning.component.scss'],
})
export class SimpleWarningComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit() {}
}
