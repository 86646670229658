import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { CustomerService } from 'app/shared/api/customer.service';
import { Customer } from 'app/shared/models/customer';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

// import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
  styleUrls: ['./print.css'],
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  // private sidebarPS: PerfectScrollbar;

  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public currentCustomer: Customer = null;
  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private customerService: CustomerService,
    private authenticationService: AuthenticationService
  ) {}

  production = environment.production;
  customerId = this.authenticationService.tokenPayLoadSubject.value.customer;

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      // this.hasIconTypeMenuItem = !this.menuItems.filter(item => item.type === 'icon').length;
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === 'icon'
      ).length;
    });

    if (
      !this.production &&
      this.customerId == 1 &&
      !this.menuItems.some((item) => item.state === 'homologation')
    ) {
      this.menuItems.push({
        name: 'Nave.Homologation',
        type: 'link',
        tooltip: 'Homologation',
        icon: 'how_to_reg',
        state: 'homologation',
      });
    }

    this.customerService.getOneCustomer(this.customerId).subscribe((data) => {
      this.currentCustomer = data;
      let itemsToRemove = ['Nave.CadastralValidation'];
      if (this.currentCustomer.pais.codigo !== 'BR') {
        itemsToRemove.push(
          'Nave.ConsultationCPF',
          'Nave.Discover',
          'Nave.DetectiveControl'
        );
      }
      this.menuItems = this.menuItems.filter(
        (item) => !itemsToRemove.includes(item.name)
      );
    });

    this.customerService
      .getCurrentCustomer()
      .subscribe((customer: Customer) => {
        this.currentCustomer = customer;
      });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.sidebarPS = new PerfectScrollbar('#scroll-area', {
    //     suppressScrollX: true
    //   })
    // })
  }

  ngOnDestroy() {
    // if(this.sidebarPS) {
    //   this.sidebarPS.destroy();
    // }
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
}
