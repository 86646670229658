import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { EditLayoutComponent } from './shared/components/layouts/edit-layout/edit-layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: '/qualification',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session' },
      },
    ],
  },
  {
    path: '',
    component: EditLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'administration',
        loadChildren:
          './views/administration/administration.module#AdministrationModule',
        data: { title: 'Administration', breadcrumb: 'Administration' },
      },
      {
        path: 'supplier',
        loadChildren: './views/supplier/supplier.module#SupplierModule',
        data: { title: 'Supplier', breadcrumb: 'Supplier' },
      },
      {
        path: 'supplier-discover',
        loadChildren:
          './views/supplier-discover/supplier.module#SupplierModule',
        data: { title: 'Discover', breadcrumb: 'Discover' },
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'basic-dashboard',
        loadChildren:
          './views/basic-dashboard/basic-dashboard.module#DashboardModule',
        data: { title: 'Dashboard', breadcrumb: 'Dashboard' },
      },
      {
        path: 'bulk-qualification',
        loadChildren:
          './views/bulk-qualification/bulk-qualification.module#BulkQualificationModule',
        data: {
          title: 'Bulk Qualification',
          breadcrumb: 'BulkQualification.Batch',
        },
      },
      {
        path: 'help-center',
        loadChildren:
          './views/help-center/help-center.module#HelpCenterModule',
        data: {
          title: 'Help Center',
          breadcrumb: 'HelpCenter.Help',
        },
      },
      {
        path: 'qualification',
        loadChildren:
          './views/qualification/qualification.module#QualificationModule',
        data: {
          title: 'Qualification',
          breadcrumb: 'Qualification.Qualification',
        },
      },
      {
        path: 'consultation',
        loadChildren: './views/scanner/scanner.module#ScannerModule',
        data: {
          title: 'Consultation',
          breadcrumb: 'Consultation.Consultation',
        },
      },
      {
        path: 'risk-tree',
        loadChildren: './views/risk-tree/risk-tree.module#RiskTreeModule',
        data: { title: 'RiskTree', breadcrumb: 'RiskTree' },
      },
      {
        path: 'homologation',
        loadChildren:
          './views/homologation/homologation.module#QualificationModule',
        data: {
          title: 'Homologation',
          breadcrumb: 'Homologação',
        },
      },
      {
        path: 'consultation-cpf',
        loadChildren: './views/scanner-cpf/scanner.module#ScannerModule',
        data: {
          title: 'Consultation CPF',
          breadcrumb: 'Consultation.Consultation',
        },
      },
      {
        path: 'cadastral-validation',
        loadChildren:
          './views/cadastral-validation/cadastral-validation.module#CadastralValidationModule',
        data: {
          title: 'CadastralValidation',
          breadcrumb: 'CadastralValidation',
        },
      },
      {
        path: 'detective-control',
        loadChildren:
          './views/detective-control/detective-control.module#CadastralValidationModule',
        data: {
          title: 'DetectiveControl',
          breadcrumb: 'DetectiveControl',
        },
      },
      {
        path: 'supplier-monitoring',
        loadChildren:
          './views/supplier-monitoring/supplier-monitoring.module#SupplierMonitoringModule',
        data: { title: 'SupplierMonitoring', breadcrumb: 'SupplierMonitoring' },
      },
      {
        path: 'reports',
        loadChildren: './views/reports/reports.module#ReportsModule',
        data: {
          title: 'Reports',
          breadcrumb: 'Reports',
        },
      },
      {
        path: 'analytics',
        loadChildren: './views/report/report.module#ReportModule',
        data: { title: 'Analytics', breadcrumb: 'Analytics' },
      },
      {
        path: 'service-status',
        loadChildren: './views/status/status.module#ServiceStatusModule',
        data: { title: 'Status', breadcrumb: 'Status' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'sessions/404',
  },
];
