import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OrderIntegration } from 'app/shared/models/orderIntegration.model';

@Component({
  selector: 'sts-orderIntegration-log-dialog',
  templateUrl: './orderIntegration-log-dialog.component.html',
  styleUrls: ['./orderIntegration-log-dialog.component.scss'],
})
export class OrderIntegrationLogDialogComponent implements OnInit {
  public orderIntegration: OrderIntegration;
  public identificadorFullJson: any;

  constructor(
    public dialogRef: MatDialogRef<OrderIntegrationLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public orderIntegraçãoData: OrderIntegration
  ) {}

  ngOnInit() {
    let identificadorParteJson;
    this.orderIntegration = this.orderIntegraçãoData;
    this.identificadorFullJson = JSON.parse(
      this.orderIntegration.identificador
    );
    identificadorParteJson = JSON.parse(
      this.identificadorFullJson.dadosIntegracaoJson
    );
    this.identificadorFullJson.dadosIntegracaoJson = identificadorParteJson;
  }

  fechar(): void {
    this.dialogRef.close();
  }
}
