import { Injectable, InjectionToken, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AvatarModule } from 'ngx-avatar';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSnackBarModule,
  MatStepperModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './components/header-side/header-side.component';
import { SidebarSideComponent } from './components/sidebar-side/sidebar-side.component';

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './components/header-top/header-top.component';
import { SidebarTopComponent } from './components/sidebar-top/sidebar-top.component';

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from './components/customizer/customizer.component';

// ALL TIME REQUIRED
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AppComfirmComponent } from './services/app-confirm/app-confirm.component';
import { AppAlertComponent } from './services/app-alert/app-alert.component';
import { AppLoaderComponent } from './services/app-loader/app-loader.component';

// DIRECTIVES
import { FontSizeDirective } from './directives/font-size.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { AppDropdownDirective } from './directives/dropdown.directive';
import { DropdownAnchorDirective } from './directives/dropdown-anchor.directive';
import { DropdownLinkDirective } from './directives/dropdown-link.directive';
import { EgretSideNavToggleDirective } from './directives/egret-side-nav-toggle.directive';

// PIPES
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { ExcerptPipe } from './pipes/excerpt.pipe';
import { GetValueByKeyPipe } from './pipes/get-value-by-key.pipe';
import { QsaPipe } from './pipes/qsa.pipe';
import { LocalDateTimePipe } from './pipes/local-date-time.pipe';
import { GetTemplateNomeInOrderPipe } from './pipes/get-template-nome-in-order.pipe';

// SERVICES
import { ThemeService } from './services/theme.service';
import { LayoutService } from './services/layout.service';
import { NavigationService } from './services/navigation.service';
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './services/auth/auth.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppAlertService } from './services/app-alert/app-alert.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor, RetryElasticsearchInterceptor } from './interceptors';
import { AuthenticationService } from './services/auth/authentication.service';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { CnaeFormatPipe } from './pipes/cnae-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { AtividadePrincipalPipe } from './pipes/atividade-principal-pipe ';
import { AtividadeSecundariaPipe } from './pipes/atividade-secundaria-pipe';
import { OrderIntegrationLogDialogComponent } from './components/integration-log-dialog/orderIntegration-log-dialog.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ResultsLogDialogComponent } from './components/results-log-dialog/results-log-dialog.component';
import { EditLayoutComponent } from './components/layouts/edit-layout/edit-layout.component';
import { TemplateRuleDialogComponent } from './components/template-rule-dialog/template-rule-dialog.component';
import { RazaoSocialPipe } from './pipes/razao-social.pipe';
import { Observable } from 'rxjs';
import { ReportService } from './services/report.service';
import { Ng5SliderModule } from 'ng5-slider';
import { ResultService } from './services/result.service';
import { DateTimeFormatIsoPipe } from './pipes/datetime-format-iso-pipe';
import { ReportComponentService } from './services/report-component.service';
import { DateTimeFormatFirestorePipe } from './pipes/date-time-fomart-firestore.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { RequalificationListDialogComponent } from './components/requalification-list-dialog/requalification-list-dialog.component';
import {
  RequalificationScheduleDialogComponent
} from './components/requalification-schedule-dialog/requalification-schedule-dialog.component';
import { MultiselectAutocompleteComponent } from './multiselect-autocomplete/multiselect-autocomplete.component';
import { AlertDialogComponent } from './components/dialog/dialog.component';
import { SupplierDatasInternationalComponent } from './components/supplier-datas-international/supplier-datas-international.component';
import { WarningAlertComponent } from './components/warning-alert/warning-alert.component';

/**
 * `HttpHandler` which applies an `HttpInterceptor` to an `HttpRequest`.
 */
export class MyHttpInterceptorHandler implements HttpHandler {
  constructor(
    private next: HttpHandler,
    private interceptor: HttpInterceptor
  ) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}

export class MyHandlerService implements HttpHandler {
  private chain: HttpHandler | null = null;

  constructor(
    private backend: HttpBackend,
    private injector: Injector,
    private interceptors: InjectionToken<HttpInterceptor[]>
  ) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (this.chain === null) {
      const interceptors = this.injector.get(this.interceptors, []);
      this.chain = interceptors.reduceRight(
        (next, interceptor) => new MyHttpInterceptorHandler(next, interceptor),
        this.backend
      );
    }
    return this.chain.handle(req);
  }
}

export const HTTP_INTERCEPTORS_1 = new InjectionToken<HttpInterceptor[]>(
  'HTTP_INTERCEPTORS_1'
);

@Injectable({ providedIn: 'root' })
export class Http1Service extends HttpClient {
  constructor(backend: HttpBackend, private injector: Injector) {
    super(new MyHandlerService(backend, injector, HTTP_INTERCEPTORS_1));
  }
}

/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  EditLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppAlertComponent,
  AppLoaderComponent,
  CustomizerComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  OrderIntegrationLogDialogComponent,
  ResultsLogDialogComponent,
  RequalificationListDialogComponent,
  RequalificationScheduleDialogComponent,
  MultiselectAutocompleteComponent,
  TemplateRuleDialogComponent,
  SupplierDatasInternationalComponent,
  WarningAlertComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    MatStepperModule,
    PerfectScrollbarModule,
    NgxJsonViewerModule,
    Ng5SliderModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    AvatarModule,
  ],
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    AppAlertComponent,
    AlertDialogComponent,
  ],
  providers: [
    ThemeService,
    LayoutService,
    NavigationService,
    ReportService,
    ResultService,
    ReportComponentService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppAlertService,
    AppLoaderService,
    DateTimeFormatPipe,
    DateFormatPipe,
    LocalDateTimePipe,
    GetTemplateNomeInOrderPipe,
    CnaeFormatPipe,
    QsaPipe,
    RazaoSocialPipe,
    AtividadePrincipalPipe,
    AtividadeSecundariaPipe,
    DateTimeFormatIsoPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS_1,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS_1,
      useClass: RetryElasticsearchInterceptor,
      multi: true,
    },
    AuthenticationService,
    Http1Service,
    // LandingPageService
  ],
  declarations: [
    classesToInclude,
    DateTimeFormatPipe,
    CnaeFormatPipe,
    QsaPipe,
    RazaoSocialPipe,
    AtividadePrincipalPipe,
    AtividadeSecundariaPipe,
    DateFormatPipe,
    LocalDateTimePipe,
    GetTemplateNomeInOrderPipe,
    DateTimeFormatIsoPipe,
    DateTimeFormatFirestorePipe,
    TruncatePipe,
    AlertDialogComponent,
  ],
  exports: [
    classesToInclude,
    DateTimeFormatPipe,
    CnaeFormatPipe,
    QsaPipe,
    RazaoSocialPipe,
    AtividadePrincipalPipe,
    AtividadeSecundariaPipe,
    DateFormatPipe,
    MatStepperModule,
    LocalDateTimePipe,
    GetTemplateNomeInOrderPipe,
    DateTimeFormatIsoPipe,
    DateTimeFormatFirestorePipe,
    TruncatePipe,
  ],
})
export class SharedModule {}
