import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { Paises } from '../models/paises.model';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private apiUrl = environment.partialApiUrl;
  private cachedCountries: BehaviorSubject<Paises[] | null> =
    new BehaviorSubject<Paises[] | null>(null);

  constructor(private http: HttpClient) {}

  getCountries(): Observable<Paises[]> {
    if (!this.cachedCountries.value) {
      this.http.get<Paises[]>(`${this.apiUrl}/sts/paises`).subscribe((data) => {
        this.cachedCountries.next(data);
      });
    }
    return this.cachedCountries.asObservable();
  }
}
