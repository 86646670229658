import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Customer } from '../models/customer';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  fullApiUrl = environment.fullApiUrl;
  private customerCache = new Map<number, Customer>(); // Cache de clientes
  public currentCustomerSubject = new BehaviorSubject<Customer>(new Customer());

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  /** Obtém um cliente pelo ID, utilizando cache */
  public getOneCustomer(idCustomer: number): Observable<Customer> {
    if (this.customerCache.has(idCustomer)) {
      return of(this.customerCache.get(idCustomer)!); // Retorna do cache
    }

    return this.http
      .get<Customer>(`${this.fullApiUrl}/customers/${idCustomer}`)
      .pipe(
        tap((customer) => this.customerCache.set(idCustomer, customer)) // Salva no cache
      );
  }

  /** Obtém o cliente atual e armazena no BehaviorSubject */
  public getCurrentCustomer(): Observable<Customer> {
    const customerId =
      this.authenticationService.tokenPayLoadSubject.value.customer;
    this.getOneCustomer(customerId).subscribe((customer) => {
      this.currentCustomerSubject.next(customer);
    });
    return this.currentCustomerSubject.asObservable();
  }

  /** Atualiza os dados do cliente */
  public putCustomer(customer: Customer): Observable<Customer> {
    const customerId =
      this.authenticationService.tokenPayLoadSubject.value.customer;
    return this.http
      .put<Customer>(`${this.fullApiUrl}/customers/${customerId}`, customer)
      .pipe(
        tap((updatedCustomer) =>
          this.customerCache.set(customerId, updatedCustomer)
        ) // Atualiza o cache
      );
  }
}
