import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';
import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'UQualify';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.changePageTitle();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.includes('/sessions/signin')) {
          this.loadScripts();
        }
      });
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
  }

  loadScripts(): void {
    if (!document.getElementById('widget-script')) {
      // const configScript = document.createElement('script');
      // configScript.type = 'text/javascript';
      // configScript.innerHTML = `
      //   window.fwSettings = { widget_id: 103000007310 };
      //   !(function () {
      //     if (typeof window.FreshworksWidget !== 'function') {
      //       var n = function () {
      //         n.q.push(arguments);
      //       };
      //       n.q = [];
      //       window.FreshworksWidget = n;
      //     }
      //   })();
      // `;
      // document.head.appendChild(configScript);
      // // Adiciona o script principal do widget
      // const widgetScript = document.createElement('script');
      // widgetScript.id = 'widget-script';
      // widgetScript.type = 'text/javascript';
      // widgetScript.src =
      //   'https://euc-widget.freshworks.com/widgets/103000007310.js';
      // widgetScript.async = true;
      // widgetScript.defer = true;
      // document.head.appendChild(widgetScript);
    }
  }

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        var routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) return this.title.setTitle(this.appTitle);
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
