import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { UserService } from 'app/shared/api/user.service';
import { User } from 'app/shared/models/user';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;

  public availableLangs: Array<any> = [
    {
      name: 'EN',
      code: 'en',
      flag: 'flag-icon-us',
    },
    {
      name: 'ES',
      code: 'es',
      flag: 'flag-icon-es',
    },
    {
      name: 'PT',
      code: 'pt',
      flag: 'flag-icon-pt',
    },
  ];
  currentLang: any = null;

  public egretThemes;
  public layoutConf: any;
  public currentUser: User = null;
  public userName: string;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authentication: AuthenticationService,
    private userService: UserService
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    // this.translate.use(this.currentLang.code);
    this.currentLang = this.availableLangs.filter(
      (x) => x.code === this.translate.getDefaultLang()
    )
      ? this.availableLangs.filter(
          (x) => x.code === this.translate.getDefaultLang()
        )[0]
      : this.availableLangs[2];

    // this.currentUser$ = new Observable<User>();

    this.userService.getCurrentUser().subscribe((user: User) => {
      this.currentUser = user;
      if (this.currentUser.username) {
        this.userName = this.currentUser.username.includes('@')
          ? this.currentUser.username.split('@')[0]
          : this.currentUser.nome;
      }
    });
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  async logout() {
    await this.authentication.logout();
    location.reload();
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full',
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact',
      },
      { transitionClass: true }
    );
  }
}
