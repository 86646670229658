import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-alert',
  templateUrl: './warning-alert.component.html',
  styleUrls: ['./warning-alert.component.css'],
})
export class WarningAlertComponent implements OnInit {
  @Input() message: string;
  @Input() alertType: 'irregular' | 'inactive';
  showAlert: boolean = true;

  get icon(): string {
    switch (this.alertType) {
      case 'inactive':
        return 'error';
      case 'irregular':
        return 'warning';
      default:
        return 'info';
    }
  }
  ngOnInit() {}
  closeAlert() {
    this.showAlert = false;
  }
}
