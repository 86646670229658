import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { environment } from 'environments/environment';
import { TemplateRule } from '../models/templateRule';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TemplateRulesService {
  fullApiUrl = environment.fullApiUrl;
  apiUrl = environment.partialApiUrl;

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService
  ) {}

  //  getOneCustomerTemplatesRules(idUser: number){
  //      return this.http.get<User>(this.apiUrl + '/customers/' + this.authentication.tokenPayLoadSubject.value.customer +'/users/' + idUser );
  //  }

  public editCustomerTemplateRule(
    templateRule: TemplateRule,
    templateId: number
  ): Observable<TemplateRule> {
    return this.http.put<TemplateRule>(
      this.fullApiUrl + '/templates-rules/' + templateId,
      templateRule
    );
  }

  public postCustomerTemplateRule(
    templateRule: TemplateRule
  ): Observable<TemplateRule> {
    return this.http.post<TemplateRule>(
      this.fullApiUrl + '/templates-rules',
      templateRule
    );
  }

  public getOneCustomerTemplateRule(
    idTemplate: number
  ): Observable<TemplateRule> {
    return this.http.get<TemplateRule>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/templates-rules/' +
        idTemplate
    );
  }
}
