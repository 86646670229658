import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { environment } from 'environments/environment';
import { User } from '../models/user';
import { ResponsePageable } from '../models/responsePageable.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PageEvent } from '@angular/material';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  private userSub: string | null = null;
  fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;
  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(
    new User()
  );

  // Cache do usuário autenticado
  private currentUserCache: User | null = null;

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService
  ) {
    this.authentication.tokenPayLoadSubject
      .asObservable()
      .subscribe((payload) => (this.userSub = payload ? payload.sub : null));
  }

  /** Obtém os detalhes do usuário autenticado */
  public getCustomerDetails(): Observable<any> {
    if (!this.userSub) {
      throw new Error('Usuário não autenticado.');
    }
    return this.http.get<any>(
      `${this.partialApiUrl}/users/roles/${this.userSub}`
    );
  }

  /** Obtém os detalhes de um usuário pelo ID */
  public getUserDetails(userId: string): Observable<any> {
    return this.http.get<any>(`${this.partialApiUrl}/users/roles/${userId}`);
  }

  /** Obtém a lista de usuários de um cliente específico */
  public getUsers(customerId: string): Observable<any> {
    return this.http.get<any>(
      `${this.fullApiUrl}/customers/${customerId}/users?page=0`
    );
  }

  /** Atualiza o papel (role) do usuário */
  public updateRole(userId: string, role: string): Observable<any> {
    const params = new HttpParams().set('role', role);
    return this.http.post<any>(
      `${this.partialApiUrl}/users/roles/${userId}`,
      null,
      { params }
    );
  }

  /** Obtém um usuário específico de um cliente */
  public getOneCustomerUser(idUser: number): Observable<User> {
    return this.http.get<User>(
      `${this.fullApiUrl}/customers/${this.authentication.tokenPayLoadSubject.value.customer}/users/${idUser}`
    );
  }

  /** Adiciona um usuário a um cliente */
  public postCustomerUser(user: User): Observable<User> {
    return this.http.post<User>(
      `${this.fullApiUrl}/customers/${this.authentication.tokenPayLoadSubject.value.customer}/users`,
      user
    );
  }

  public postCustomerUserWithCustomerId(
    user: User,
    customerId: string
  ): Observable<User> {
    return this.http.post<User>(
      `${this.fullApiUrl}/customers/${customerId}/users`,
      user
    );
  }

  public postUserRoleMaster(idUser: number, role: string): Observable<User> {
    return this.http.post<User>(`${this.fullApiUrl}/users/${idUser}/roles`, {
      roleName: role,
    });
  }

  public postCustomerUserOauthWithCustomerId(
    user: User,
    customerId: string
  ): Observable<User> {
    return this.http.post<User>(
      `${this.fullApiUrl}/user/oauth/${customerId}`,
      user
    );
  }

  public deleteCustomerUser(user: User): Observable<User> {
    return this.http.delete<User>(
      `${this.fullApiUrl}/customers/${this.authentication.tokenPayLoadSubject.value.customer}/users/${user.id}`
    );
  }

  public putCustomerUser(user: User): Observable<any> {
    return this.http.put(
      `${this.fullApiUrl}/customers/${this.authentication.tokenPayLoadSubject.value.customer}/users/${user.id}`,
      user
    );
  }

  /** Obtém o usuário autenticado com cache */
  public getCurrentUser(): Observable<User> {
    if (this.currentUserCache) {
      return of(this.currentUserCache); // Retorna do cache se já estiver carregado
    }

    return this.http
      .get<User>(
        `${this.fullApiUrl}/customers/${this.authentication.tokenPayLoadSubject.value.customer}/userPrincipal`,
        {
          params: new HttpParams().set(
            'username',
            this.authentication.tokenPayLoadSubject.value.sub
          ),
        }
      )
      .pipe(
        tap((user: User) => {
          this.currentUserCache = user; // Armazena no cache
          this.currentUserSubject.next(user); // Atualiza o BehaviorSubject
        })
      );
  }

  public checkUsernamExists(username: string): Observable<Boolean> {
    const checked: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(
      false
    );
    this.http
      .get<ResponsePageable>(`${this.fullApiUrl}/users/`, {
        params: new HttpParams().set('username', username),
      })
      .subscribe((result: ResponsePageable) => {
        checked.next(result.totalElements > 0);
      });
    return checked.asObservable();
  }

  public getAllCustomerUsers(
    pageEvent: PageEvent
  ): Observable<ResponsePageable> {
    let params = new HttpParams();
    params = params.append(
      'page',
      pageEvent.pageIndex ? pageEvent.pageIndex.toString() : '0'
    );
    params = params.append(
      'size',
      pageEvent.pageSize ? pageEvent.pageSize.toString() : '0'
    );

    return this.http.get<ResponsePageable>(
      `${this.fullApiUrl}/customers/${this.authentication.tokenPayLoadSubject.value.customer}/users`,
      { params: params }
    );
  }

  public getUsersLog(
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      `${this.partialApiUrl}/userLog?page=${pageIndex}&size=${pageSize}`
    );
  }
}
