export const environment = {
  production: false,
  partialApiUrl: 'https://api-qa.uqualifyapp.com',
  fullApiUrl: 'https://api-qa.uqualifyapp.com/sts',
  apiUrlAuth: 'https://api-qa.uqualifyapp.com/auth',
  wolffishApiUrl: 'https://api-qa.uqualifyapp.com',
  docsFilesBaseUrl: 'https://s3.amazonaws.com/sts-doc-stag/',
  filesFilesBaseUrl: 'https://s3.amazonaws.com/sts-files-stag/',
  firebaseConfig: {
    apiKey: 'AIzaSyBsGq33AuFrlkO9T-fCF-EjM0oFa2XKC-E',
    authDomain: 'uqualify-fire.firebaseapp.com',
    databaseURL: 'https://uqualify-fire.firebaseio.com',
    projectId: 'uqualify-fire',
    storageBucket: 'uqualify-fire.appspot.com',
    messagingSenderId: '978831942460',
    appId: '1:978831942460:web:c8e8ed4ac1e479b4cd3439',
  },
  b3: {
    id: '162577',
    clientId: 'f05d81fe-2436-4450-a333-00d9f6184d7f',
    authority:
      'https://login.microsoftonline.com/f9cfd8cb-c4a5-4677-b65d-3150dda310c9/',
  },
  keycloack: {
    issuer: 'https://auth.stratesys.app/auth/realms/uqualify-stag',
    tokenEndpoint:
      'https://auth.stratesys.app/auth/realms/uqualify-stag/protocol/openid-connect/token',
    requestAccessToken: true,
    redirectUri: 'https://dev.uqualifyapp.com/qualification',
    waitForTokenInMsec: 10,
    clientId: 'uqualify-web',
    responseType: 'code',
    disableAtHashCheck: true,
    oidc: true,
    requireHttps: false,
    showDebugInformation: true,
    scope: 'openid profile email offline_access',
  },
  sso: {
    b3: 'https://auth.stratesys.app/auth/realms/uqualify-stag/protocol/openid-connect/auth?response_type=code&client_id=uqualify-token&redirect_uri=https://dev.uqualifyapp.com/sessions/oauth/callback&kc_idp_hint=b3',
    hidrotermica:
      'https://auth.stratesys.app/auth/realms/uqualify-stag/protocol/openid-connect/auth?response_type=code&client_id=uqualify-token&redirect_uri=https://dev.uqualifyapp.com/sessions/oauth/callback&kc_idp_hint=hidrotermica',
    grupoboticario:
      'https://auth.stratesys.app/auth/realms/uqualify-stag/protocol/openid-connect/auth?response_type=code&client_id=uqualify-token&redirect_uri=https://dev.uqualifyapp.com/sessions/oauth/callback&kc_idp_hint=grupoboticario',
    dpsp: 'https://auth.stratesys.app/auth/realms/uqualify-stag/protocol/openid-connect/auth?response_type=code&client_id=uqualify-token&redirect_uri=https://dev.uqualifyapp.com/sessions/oauth/callback&kc_idp_hint=dpsp',
    ems: 'https://auth.stratesys.app/auth/realms/uqualify-stag/protocol/openid-connect/auth?response_type=code&client_id=uqualify-token&redirect_uri=https://dev.uqualifyapp.com/sessions/oauth/callback&kc_idp_hint=ems',
    redirect_uri: 'https://dev.uqualifyapp.com/sessions/oauth/callback',
    client_id: 'uqualify-token',
    client_secret: 'KDxYgUobsIWqRmP38eOdGW7UctgbXCui',
  },
};
