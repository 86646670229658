import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-document-source-dialog.component',
  templateUrl: './document-source-dialog.component.html',
  styleUrls: ['./document-source-dialog.component.scss'],
})
export class DocumentSourceDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DocumentSourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  closeDialog(): void {
    this.dialogRef.close(null);
  }
}
